<template>
    <div class="mainContainerPackageName">
            <div style="display: flex; align-items: center; gap: 8px; width: 100%;">
                <img src="https://s3iconimages.mymedicine.com.mm/boxTick.svg" alt="">
                <div style="width: 100%; display: flex; align-items: flex-start; flex-direction: column; flex:1; width: 100%;">
                    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
                        <span style="font-weight: 500; width: 65%; text-align: left;">{{ isEngLanguage() ?  item.packageNameEN : item.packageNameMM }}</span>
                        <div style="width: 35%; text-align: right;">
                            <span> {{$t("Customer.MyFamily.Age")}}: ({{ item.ageAllowedFrom }} - {{ item.ageAllowedTo }})</span>
                        </div>
                    </div>
                    <div style="color: #1467bf; font-weight: 400; font-size: 14px; display: flex; justify-content: space-between; width: 100%;">
                        <div style="flex: 3; flex-wrap: wrap; display: flex;">
                            <span style="font-weight: 500; font-size: 14px;" v-for="(applicable, index) in item.applicableFor" :key="index">{{ applicable }}{{ index != item.applicableFor.length -1 ? ',' : ''  }} </span>
                        </div>
                        <span v-if="showTestCount" style="flex: 1; text-align: right;"> {{ item.testList.length }} {{$t("Customer.PackageAppointments.tests")}}</span>
                    </div>

                </div>
            </div>
    </div>

</template>

<script>
import { axios_auth_instance_hba_customer } from '../../../../utils/axios_utils';

export default {
    name: 'OPDPackageCommongPackageNameAndTestCountComp',
    props: ["showTestCount", "item"], 
    data: function() {
        return {
            showTestDetails: false,
            viewTestDetailsModel: false,
            // dependent_members: []
        };
    },
    async mounted() {
        
        this.allowedDates = this.getXDaysFromToday(90);
    },
    computed: {
        hospitalData () {
            return this.$store.state.particularHospitalState.hospitalInfo;        
        },
    },
    methods: {
        isEngLanguage() {
            try {
                return this.$i18n.locale=='en' ;
            } catch (error) {
                return true;
            }
        },
        async openDoctorBox() {

            try {
                if (this.doctorData == null) {
                    let apiResponse = await axios_auth_instance_hba_customer(`/opd/doctorInfo/${this.item.doctorInfo.id}`);
                    this.doctorData = apiResponse.data.doctorDetails;
                }
                this.viewDoctorModel = true;

            } catch (error) {
                console.log(error);
            }
        },
        toggleViewDoctorDialog(value) {
            if(value) {
                this.viewDoctorModel = true;
            } else {
                this.viewDoctorModel = false;
            }
        },
        formatDate(dateString) {
        // Extract year, month, and day from the given string
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6) - 1; // Months are zero-based in JavaScript
        const day = dateString.substring(6, 8);

        // Create a new Date object
        const date = new Date(year, month, day);

        // Get the day and month name
        const dayName = date.toLocaleString('en-US', { weekday: 'long' });
        const monthName = date.toLocaleString('en-US', { month: 'long' });

        // Format the date
        const formattedDate = `${day} ${monthName}`;

        return formattedDate;
        },

    openReviewBox() {
        console.dir(this.selectedDateItem);
        console.log(this.selectedID);
        this.bookingInfo = {
            'doctorInfo': {
                doctorName: this.item.doctorInfo.doctorNameEN,
                doctorLogoURL: this.item.doctorInfo.doctorLogo,
                doctorSpecialty: this.item.doctorInfo.doctorSpecialty,
                hospitalLogo: this.hospitalData.hospitalLogo,
                hospitalName: this.hospitalData.name,
                hospitalAddress: this.hospitalData.address,
                slotTime: this.timeInfo,
                slotDate: '24 Aug'                
            }
        }
        this.reviewDialg = true;
        console.dir(this.bookingInfo);
    },
    isDateAllowed(date) {
      console.log(date);
      return this.allowedDates.includes(date);
    },
    getXDaysFromToday(x) {
        const dates = [];
        const today = new Date();
        for (let i = 0; i < x; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            const yyyy = date.getFullYear();
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            dates.push(`${yyyy}-${mm}-${dd}`);
        }
        return dates;
    },
  calculateDateTime(hours, dateNumber, startTime) {
    console.log(hours, dateNumber, startTime);
    const today = new Date();
    hours = Number(hours);
    today.setHours(today.getHours() + hours);
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const dd = String(today.getDate()).padStart(2, '0');
    const HH = String(today.getHours()).padStart(2, '0');
    const mmFormat = String(today.getMinutes()).padStart(2, '0');
    const dateObject = {
        dateValue: Number(`${yyyy}${mm}${dd}`),
        time: `${HH}:${mmFormat}`
    };
    // Check if dateNumber and startTime are greater than dateObject values
    const inputDateValue = Number(dateNumber);
    const inputTimeValue = Number(startTime.replace(':', ''));
    let allowed = inputDateValue > dateObject.dateValue ||
            (inputDateValue === dateObject.dateValue && inputTimeValue > Number(dateObject.time.replace(':', '')));
            console.log(allowed);
    return allowed;
    },

        async bookSlot() {
            this.bookingLoader = true;
            try {
                let apiResponse = await axios_auth_instance_hba_customer.post('/opd/book', {
                    hospital_ID: this.item.doctorInfo.hospitalRegNo,
                    slot_ID: `${this.item.doctorInfo.id}#${this.selectedSlotId}`,
                    booked_for: this.dependentSelected
                });
                let bookingID = apiResponse.data.bookingID;
                this.$router.push({
                    name: 'hospitalBookingSuccess',
                    params: {
                        bookingID: bookingID,
                    }
                });
            } catch (error) {
                console.log(error);
            } finally {
                this.bookingLoader = false;
            }
        },
        async toggleSlot() {
            this.viewTestDetailsModel = !this.viewTestDetailsModel;
            // this.selectedID = null;
            // this.selectedDateItem = null;
            // this.selectedSlotId = null;
            // this.showAddPatient = false;
            // this.showSelectSlot = false;
            // if (this.showTestDetails === false) {
            //     try {
            //         this.slotLoader = true;
            //         let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/slotInfo/${this.item.doctorInfo.hospitalRegNo}/${this.item.doctorInfo.id}`);
            //         this.slotInfo = apiResponse.data.slotList;
            //         this.cutOffHours = apiResponse.data.cutOffHour;
                
            //         this.slotInfo.push({
            //                 slotID: 'CALENDAR',   
            //             }
            //         );
            //         this.showTestDetails = true;
            //     } catch (error) {
            //         console.log(error);
            //     } finally {
            //         this.slotLoader = false;
            //     }
            // } else {
            //     this.showTestDetails = false;
            // }

        },
        callme() {
            this.bookingAllowed = true;
        },
        async getParticularDateData() {
            try {
                this.calendarLoader = true;
                this.overlay = true;
                let splitedDate = this.datePickerModel.split('-');
                let dateInput = splitedDate[0] + splitedDate[1] + splitedDate[2];
                let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/slotInfo/${this.item.doctorInfo.hospitalRegNo}/${this.item.doctorInfo.id}/${dateInput}`);
                if(apiResponse.data.slotList.length == 0) {
                    alert('No slots available');
                    return;
                }
                this.selectedDateItem = {
                    slotID: 'CALENDAR',
                    slotTimes: apiResponse.data.slotList
                }
                this.calendarDateNumber = dateInput;
                this.showSelectSlot = true;
                this.openCalender = false;
            } catch (error) {
                console.log(error);
            } finally {
                this.calendarLoader = false;
            }

        },  
        getClassForDateBox(index, slotItem) {
            // if(slotItem.slotID == 'CALENDAR' && index != this.selectedID) return "dateBox"

            if(slotItem.slotID != 'CALENDAR' && slotItem.slotTimes.length == 0) {
                return "dateBox disabledDateBox"
            }
            if(index == this.selectedID) {
                return "dateBox selectedDateBox"
            } else {
                return "dateBox"
            }
        },
        getClassForSlotBox(index) {
            if(index == this.selectedSlotId) {
                return "slotBox selectedDateBox"
            } else {
                return "slotBox"
            }
        },
        showSelectSlotFn(id, slotItem) {
            this.selectedID = null;
            this.selectedDateItem = null;
            this.selectedSlotId = null;
            this.showAddPatient = false;
            this.showSelectSlot = false;
            // if(id == 'CALENDAR') return;
            if(id == 'CALENDAR') {
                this.selectedID = id;
                this.selectedDateItem = slotItem;
                this.openCalender = true;
                return;
            }
            if(slotItem.slotTimes.length == 0) return;
            this.selectedID = id;
            this.selectedDateItem = slotItem;
            this.showSelectSlot = true;
        },
        showAddPatientFn(id, item) {
            console.log(this.selectedDateItem);
            if(this.calculateDateTime(this.cutOffHours, this.selectedDateItem.slotID == 'CALENDAR' ? this.calendarDateNumber : this.selectedDateItem.slotID, item.startTime) == false) {
                alert('Booking not allowed');
                return;
            }
            // if any selected before
            this.selectedSlotId = null;
            this.timeInfo = null;
            this.showAddPatient = true;
            this.selectedSlotId = id;
            this.timeInfo = item.timeInfo;

        },
        goToHospitalPage() {
            this.$router.push({
            name: "ViewHospital",
            });
        },
    },
};

</script>
<style  scoped>

.mainContainerPackageName {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background: red;  */
    align-items: center;
}
.commonFlexBox {
    display: flex; flex-direction: column; gap: 4px; align-items: flex-start; 
}
.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8e8;
    position: fixed;
    width: 100%;
    max-width: 425px;
}
</style>